function getUrlVars() {
    var vars = {};
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
        vars[key] = value;
    });
    return vars;
}


if(!document.getElementById('home')){
    

        const links = document.querySelectorAll('[data-scroll]')


        for (let i = 0; i < links.length; i++){

            links[i].addEventListener('click', e => {
                e.preventDefault()
                const baseUrl = e.target.href.split('#')[0]
                window.location.href = baseUrl

            })
        }

    }
    
    // (function(){
    //     const id = getUrlVars().link
    //     setTimeout(() => {
    //         if ($(window).width() <= 480){	
    //             $('html, body').animate({
    //                 scrollTop: Math.max($('#' + id).offset().top - $('#navigation').outerHeight() - 0, 0)
    //                 }, 800, function(){
    //                 });
    //         }
    //         else if ($(window).width() >= 481 && $(window).width() <= 650){	
    //             $('html, body').animate({
    //                 scrollTop: Math.max($('#' + id).offset().top - $('#navigation').outerHeight() - 195, 0)
    //                 }, 800, function(){
    //                 });
    //         }
    //         else if ($(window).width() > 650 && $(window).width() < 768){	
    //             $('html, body').animate({
    //                 scrollTop: Math.max($('#' + id).offset().top - $('#navigation').outerHeight() - 200, 0)
    //                 }, 800, function(){
    //                 });
    //         }
    //         else if ($(window).width() == 768){	
    //             $('html, body').animate({
    //                 scrollTop: Math.max($('#' + id).offset().top - $('#navigation').outerHeight() - 200, 0)
    //                 }, 800, function(){
    //                 });
    //         }
    //         else if ($(window).width() >= 900 && $(window).width() < 999){	
    //             $('html, body').animate({
    //                 scrollTop: Math.max($('#' + id).offset().top - $('#navigation').outerHeight() - 250, 0)
    //                 }, 800, function(){
    //                 });
    //         }
    //         else if ($(window).width() >= 1000 && $(window).width() < 1200){	
    //             $('html, body').animate({
    //                 scrollTop: Math.max($('#' + id).offset().top - $('#navigation').outerHeight() - 200, 0)
    //                 }, 800, function(){
    //                 });
    //         }
    //         else if ($(window).width() >= 1300 && $(window).width() < 1400 ){	
    //             $('html, body').animate({
    //                 scrollTop: Math.max($('#' + id).offset().top - $('#navigation').outerHeight() - 240, 0)
    //                 }, 800, function(){
    //                 });
    //         }
    //         else if ($(window).width() > 1400 && $(window).width() <= 1600){	
    //             $('html, body').animate({
    //                 scrollTop: Math.max($('#' + id).offset().top - $('#navigation').outerHeight() - 300, 0)
    //                 }, 800, function(){
    //                 });
    //         }
    //         else if ($(window).width() > 1600 && $(window).width() <= 2000){	
    //             $('html, body').animate({
    //                 scrollTop: Math.max($('#' + id).offset().top - $('#navigation').outerHeight() - 385, 0)
    //                 }, 800, function(){
    //                 });
    //         }
    //         else if ($(window).width() > 2000 && $(window).width() <= 2500){	
    //             $('html, body').animate({
    //                 scrollTop: Math.max($('#' + id).offset().top - $('#navigation').outerHeight() - 460, 0)
    //                 }, 800, function(){
    //                 });
    //         }
    //         else{
    //             $('html, body').animate({
    //                 scrollTop: Math.max($('#' + id).offset().top - $('#navigation').outerHeight() - 520, 0)
    //                 }, 800, function(){
    //                 });
    //         }
    //         // $('html, body').animate({
    //         //     scrollTop: Math.max($('#' + id).offset().top, 0)
    //         //   }, 800, function(){
    //         //   });
    //         //window.location.href = '#' + id 
    
    //     }, 100)

    // })()

        

